
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import API from "@/api/API";
import store from "@/store";

export default Vue.extend({
  name: "Create",

  data: () => ({
    rules,
    model: {
      service_types: [],
      is_weight_unit: false as boolean
    },
    errorMessages: {} as { [value: string]: Array<string> },
    serviceTypes: [] as Array<SelectComponentInterface>
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const serviceTypes = await API.serviceTypes().getList();

      next(vm => {
        vm.serviceTypes = serviceTypes;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as Vue).validate()) {
          await this.$API.measureUnits().create({ ...this.model });
          await this.$router.push("/measure-units");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
